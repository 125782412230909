import React from "react"
import Layout from "../../../../components/layout"
import SEO  from "../../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const styles = require('../../../../styles/event/info.module.css')

const WabtecInfo: React.FC = () => (
    <Layout>
        <SEO title="Football Parking Info"/>
            <div className={styles.logoStripe}>
                    <StaticImage src ="../../../../images/wabteclogo.png" placeholder="blurred"
                        layout="constrained"
                        objectFit="contain"
                        height={100}
                        style={{alignContent: "center", width: "50%"}}
                        alt="Wabtec Logo" />
               
                
                    <StaticImage src ="../../../../images/uwaylogo.png" 
                        layout="constrained"
                        objectFit="contain"
                        height={100} 
                        style={{alignContent: "center", width: "50%"}}
                        alt="United Way Logo" />
            </div>
        <div className={styles.container}>
           
            <div>
                <h1>Thank you!</h1>
                <p>
                    Thank you for Living United! Because of you and the generous support of local
                    businesses like <a href="https://www.wabtec.com">Wabtec Corporation</a>, a portion of the proceeds from your purchase of
                    Gamecock football parking will go to help children in the Midlands learn to read on grade
                    level, access free dental care, and have food, shelter and a place to call home!
                </p>
                <p> If you would like to make a donation to the United Way of the Midlands, <a href="https://www.uway.org/donate">click here.</a></p>
                <h1>What is Park to Give?</h1>
                <p>
                    Park to Give is the inspiration of two Gamecock alumni with the goal of making parking
                    better for residents and guests of our Capital City – and at the same time, impact our local
                    community for good. 
                </p>
                <p>What makes parking with us different? Well, a few things:</p>
                <ul>
                    <li>All parking spaces are provided in partnership with local businesses like <a href="https://www.wabtec.com">Wabtec Corporation</a>,
                        who care about our community. These businesses use their parking
                        during business hours but make their spaces available for public paid parking during
                        special events and/or evenings and weekends.
                    </li>
                    <li>All parking is paid for using our secure mobile platform that requires no app to
                        download or registration.
                    </li>
                    <li>And most importantly, all net proceeds go to support <a href="https://www.uway.org">United Way of the Midlands</a> and 
                        their work in making a difference in our local community.
                    </li>
                </ul>
                <p><a href="https://www.parktogive.com/home/">Click here to learn more</a></p>
            </div>
        </div>
    </Layout>
)

export default WabtecInfo

